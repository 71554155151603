import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import AniLink from 'gatsby-plugin-transition-link/AniLink'
import React, { useState } from 'react'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'

// import babyPhone from '../assets/baby-pack/008-baby monitor.svg'
// import milk from '../assets/baby-pack/023-baby.svg'
import prestationWithoutFollowup from '../assets/baby-pack/028-babies.svg'
// import footsteps from '../assets/baby-pack/031-babies.svg'
// import calendar from '../assets/baby-pack/033-administration.svg'
import { default as babyNewBornCta } from '../assets/baby-pack/050-baby-mobile.svg'
// import recidiveHelp from '../assets/baby-pack/51-love.svg'
// import behavior from '../assets/baby-pack/56-broken.svg'
// import prestationWithFollowup from '../assets/baby-pack/59-girl.svg'
import deepFollowup from '../assets/baby-pack/61-swan.svg'
// import personalizedPlan from '../assets/baby-pack/72-love.svg'
// import bed from '../assets/baby-pack/73-bed.svg'
import mail from '../assets/baby-pack/79-love.svg'
// import dog from '../assets/baby-pack/80-dog.svg'
// import bear from '../assets/baby-pack/86-bear.svg'
import { default as additionnalPrestation } from '../assets/baby-pack/89-cupid.svg'
// import lollipop from '../assets/baby-pack/90-lollipop.svg'
// import chat from '../assets/baby-pack/92-chat.svg'
// import champaign from '../assets/baby-pack/95-champagne.svg'
import blob from '../assets/blobs/blob-shape.svg'
import blob2 from '../assets/blobs/blob-shape2.svg'
// import tick from '../assets/baby-pack/tick.svg'
// import phoneCall from '../assets/baby-pack/phone_call.svg'
// import question from '../assets/baby-pack/question.svg'
// import checkList from '../assets/baby-pack/checklist.svg'
import Layout from '../components/layout'
import CheckoutForm from '../components/CheckoutForm'
// import babyNewBorn from '../images/05.jpg'
// import additionnalBaby from '../images/07.jpg'

const stripePromise = loadStripe(process.env.GATSBY_STRIPE_PUBLISHABLE_KEY)

const pricing = () => {
  const [clientSecret, setClientSecret] = useState('')
  let [isOpen, setIsOpen] = useState(false)
  let [title, setTitle] = useState('')
  let [text, setText] = useState('')
  let [price, setPrice] = useState('')

  async function createPaymentIntent(item) {
    setIsOpen(true)
    const res = await fetch('/.netlify/functions/create-payment-intent', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ item: { id: item } }),
    })
    const data = await res.json()
    setClientSecret(data.clientSecret)
  }

  // function createPaymentIntent(item) {
  //   setIsOpen(true)
  //   fetch('/.netlify/functions/create-payment-intent', {
  //     method: 'POST',
  //     headers: { 'Content-Type': 'application/json' },
  //     body: JSON.stringify({ item: { id: item } }),
  //   })
  //     .then((res) => res.json())
  //     .then((data) => setClientSecret(data.clientSecret))
  // }

  // https://stripe.com/docs/elements/appearance-api
  const appearance = {
    theme: 'stripe',
    variables: {
      colorPrimary: '#805ad5',
      colorBackground: '#fff',
      colorText: '#30313d',
      colorDanger: '#df1b41',
      fontFamily: 'Ideal Sans, system-ui, sans-serif',
      spacingUnit: '4px',
      borderRadius: '4px',
    },
    rules: {
      '.Tab': {
        border: '1px solid #E0E6EB',
        boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(18, 42, 66, 0.02)',
      },

      '.Tab:hover': {
        color: 'var(--colorText)',
      },

      '.Tab--selected': {
        borderColor: '#E0E6EB',
        boxShadow:
          '0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(18, 42, 66, 0.02), 0 0 0 2px var(--colorPrimary)',
      },

      '.Input--invalid': {
        boxShadow: '0 1px 1px 0 rgba(0, 0, 0, 0.07), 0 0 0 2px var(--colorDanger)',
      },

      '.Label': {
        color: '#2d3748',
        fontWeight: 'bold',
      },

      '.Dropdown': {
        borderRadius: '4px',
      },

      '.Block': {
        colorBackground: '#fff',
      },

      // See all supported class names and selector syntax below
    },
  }
  const options = {
    clientSecret,
    appearance,
  }

  const image = useStaticQuery(graphql`
    query {
      five: file(relativePath: { eq: "05.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      seven: file(relativePath: { eq: "07.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <Layout>
      {/* <img src={typewriter} alt="analyse questionnaire" className="inline h-6" /> */}
      <section>
        {clientSecret && (
          <Elements options={options} stripe={stripePromise}>
            <CheckoutForm isOpen={isOpen} setIsOpen={setIsOpen} title={title} text={text} price={price} />
          </Elements>
        )}
      </section>
      {/* <button onClick={sendMail}>Click here to send mail</button> */}
      <div className="flex flex-col items-center h-full max-w-6xl p-6 m-4 text-left rounded-2xl md:mx-auto">
        <p className="px-4 text-2xl font-bold text-center text-justify text-purple-600 font-muli">
          &ldquo;Bébé au pays du sommeil&ldquo; vous propose plusieurs types de prestations, quel que soit
          l’âge de votre enfant et vos besoins d’accompagnement individuel. Vous allez sûrement trouver la
          formule qui vous correspond au mieux.
        </p>
        <div className="px-5 mx-auto mt-8 text-3xl text-center rounded-lg md:text-2xl underlined-shorter">
          Pour vous aider à faire votre choix, contactez-moi via ce{' '}
          <AniLink fade duration={0.7} to="/contact-form/">
            <span className="contact-form font-header">formulaire de contact</span>
          </AniLink>
        </div>
      </div>
      <div id="new-formula" className="flex flex-wrap justify-center max-w-6xl mx-auto">
        <section
          id="guide-pdf"
          className="relative flex w-10/12 max-w-3xl mx-auto mt-10 border-2 rounded-3xl border-tealy-600 bg-tealy-600"
        >
          <div className="relative flex flex-col">
            <img
              src={blob}
              className="absolute top-0 right-0 z-10 h-32 -mt-16 -mr-16 transform -rotate-270"
            />
            <span className="absolute top-0 right-0 z-10 -mt-4 -mr-8 text-sm text-gray-800">Nouveau !</span>
            <div
              id="upper-part"
              className="relative flex flex-col py-2 text-lg bg-tealy-600 rounded-tl-3xl font-muli"
            >
              <h3 className="font-bold text-center text-gray-800 mb-4 w-2/3 mx-auto md:w-full">
                <img src={mail} alt="icone de lit" className="inline h-6 mr-2 -ml-3 align-baseline" />
                Guide complet pour les futurs et jeunes parents
              </h3>
              <p className="w-full px-4 text-xl text-gray-800 md:text-base text-center">
                Le sommeil des Nouveau-Nés : Stratégies efficaces pour que votre bébé dorme mieux dès ses
                premiers jours (0 - 4 mois)
              </p>
              <span className="w-24 p-1 mx-auto mt-4 mb-3 text-base text-center text-blue-800 rounded-full bg-tealy-300 font-header">
                10 euros
              </span>
            </div>
            <div id="lower-part" className="flex flex-col pb-8 border-0 rounded-bl-3xl sm:rounded-br-3xl">
              <section className="pl-4 text-gray-900 font-muli md:text-base text-md text-center">
                {/* <span className="font-bold">Ce guide de 35 pages va vous aider à comprendre :</span>
              <ul>
                <li>- Ce qu&apos;est un environnement propice au sommeil</li>
                <li>- Le développement du bébé mois par mois</li>
                <li>- Les besoins du bébé en sommeil chaque mois</li>
                <li>- Comment aider le bébé à mieux dormir</li>
                <li>- Les premiers pas vers l&apos;autonomie du sommeil</li>
                <li>
                  - Comment éviter les pièges qui empêchent la progression naturelle du sommeil de votre bébé
                </li>
                <li>
                  Et bien plus d&apos;autres astuces qui vous aideront à profiter de votre parentalité...
                </li>
              </ul> */}
                <AniLink fade to="/description-guide/">
                  <button className="absolute bottom-0 left-0 right-0 w-7/12 px-4 py-3 mx-auto -mt-6 -mb-4 font-bold text-center text-white transition-all rounded-full shadow-sm cursor-pointer sm:-mb-6 md:-mb-6 md:ml-12 lg:ml-auto bg-fourth-pricing hover:bg-fourth-pricing-hovered transition-500">
                    En savoir plus
                  </button>
                </AniLink>
              </section>
            </div>
          </div>
        </section>
        <div className="flex justify-center w-4/5 max-w-6xl mx-auto mt-6 overflow-hidden text-transparent line-separator line-separator-purple">
          _______________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________
        </div>
      </div>
      {/* <div className="flex justify-center w-4/5 mx-auto mt-6 overflow-hidden text-transparent line-separator">
        _______________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________
      </div> */}
      <div id="main-formulas" className="flex flex-wrap justify-center max-w-6xl mx-auto">
        <section
          id="prestation-sans-accompagnement"
          className="relative flex flex-col w-10/12 h-full max-w-3xl mx-auto mt-16 xl:w-4/12 md:w-1/2 md:px-10 lg:px-8"
        >
          <div
            id="prestation-sans-accompagnement-header"
            className="relative flex flex-col px-4 bg-lilas2 rounded-t-2xl"
          >
            <img src={blob2} className="absolute top-0 right-0 h-40 -mt-20 -mr-20 transform -rotate-180" />
            <span className="absolute top-0 right-0 -mt-6 -mr-2 text-sm text-gray-800">4 mois</span>
            <span className="absolute top-0 right-0 -mt-2 -mr-2 text-sm text-gray-800">~ 4 ans</span>
            <h3 className="mt-4 text-xl font-bold text-center text-gray-800 md:text-base">
              <img
                src={prestationWithoutFollowup}
                alt="icone de mégaphone"
                className="inline h-6 mr-2 -ml-3 align-baseline"
              />
              Consultation individuelle de 1h
            </h3>
            <p className="mt-2 mb-6 text-xl font-muli md:text-base">
              Un échange avec les parents qui inclut : l&apos;analyse du journal du sommeil, l&apos;analyse
              complète de la situation avec le sommeil de votre enfant, un plan pour les axes
              d&apos;amélioration et des intruments à mettre en place.
            </p>
            <span className="absolute bottom-0 left-0 right-0 w-24 p-1 mx-auto -mb-4 text-lg text-center text-blue-800 rounded-full bg-tealy-400 font-header">
              75 euros
            </span>
          </div>
          <ul
            id="prestation-sans-accompagnement-points"
            className="px-0 pt-6 pb-10 text-xl text-center text-gray-900 border-2 bg-salmony-0 border-lilas2 rounded-b-2xl md:text-base"
          >
            <li>
              {/* <img src={babyPhone} alt="icone de mégaphone" className="inline h-6 mr-1 align-baseline" /> */}
              <span>
                - Analyse de l&apos;état de sommeil à l&apos;aide d&apos;un journal rempli par les parents
                pendant 5 jours
              </span>
            </li>
            <li>
              {/* <img src={babyPhone} alt="icone de mégaphone" className="inline h-6 mr-1 align-baseline" /> */}
              <span>
                - Analyse de l&apos;état de sommeil à l&apos;aide d&apos;un questionnaire rempli par les
                parents
              </span>
            </li>
            <li>
              {/* <img
                src={personalizedPlan}
                alt="icone de plan personnalisé"
                className="inline h-6 mr-1 align-baseline"
              /> */}
              <span>- Échange avec les parents autours du sujet pendant 1 heure</span>
            </li>
            <li>
              {/* <img src={bear} alt="icone de d'ours" className="inline h-6 mr-1 align-baseline" /> */}-
              Document avec les recommendations en forme écrite
            </li>
            <li>
              <span className="line-separator line-separator-purple tooltip">
                - Quels problèmes souhaitez-vous m&apos;adresser ?
                <span className="tooltiptext">
                  Endormissement difficile et long, veilles nocturnes, réveils nocturnes résultant d&apos;un
                  rythme innaproprié, réveils tôt le matin, siestes compliquées, absence de siestes
                </span>
              </span>
            </li>
            <button
              className="absolute bottom-0 left-0 right-0 w-7/12 p-2 mx-auto -mb-5 font-bold text-white rounded-full cursor-pointer md:-mb-4 bg-ugly-lilas hover:bg-ugly-lilas-hovered font-muli"
              onClick={() => {
                createPaymentIntent(process.env.GATSBY_CONSULT_INDIVIDUELLE_ID)
                setTitle('Consultation individuelle de 1h')
                setText(`Un échange avec les parents qui inclut: l'analyse du journal du sommeil; l'analyse
              complète de la situation avec le sommeil de votre enfant; un plan pour les axes
                d'amélioration et des intruments à mettre en place.`)
                setPrice('75,00 €')
              }}
            >
              Choisir ce forfait
            </button>
          </ul>
        </section>

        <section
          id="prestation-avec-suivi-prolonge"
          className="relative flex flex-col w-10/12 h-full max-w-3xl mx-auto mt-16 xl:w-4/12 md:w-1/2 md:px-10 lg:px-8"
        >
          <div
            id="prestation-avec-suivi-prolonge-header"
            className="relative flex flex-col px-4 bg-lilas5 rounded-t-2xl"
          >
            <img src={blob2} className="absolute top-0 right-0 h-40 -mt-20 -mr-20 transform -rotate-180" />
            <span className="absolute top-0 right-0 -mt-6 -mr-2 text-sm text-gray-800">4 mois</span>
            <span className="absolute top-0 right-0 -mt-2 -mr-2 text-sm text-gray-800">~ 6 ans</span>
            <h3 className="mt-4 text-xl font-bold text-center text-gray-800 md:text-base">
              <img
                src={deepFollowup}
                alt="icone de mégaphone"
                className="inline h-6 mr-3 -ml-3 align-baseline"
              />
              Prestation avec suivi
            </h3>
            <p className="mt-2 mb-6 text-xl font-muli md:text-base">
              Vous souhaitez atteindre le résultat en douceur, avec le support quotidien et durable de votre
              consultante.
            </p>
            <span className="absolute bottom-0 left-0 right-0 w-24 p-1 mx-auto -mb-4 text-lg text-center text-blue-800 rounded-full bg-tealy-400 font-header">
              369 euros
            </span>
          </div>
          <ul
            id="prestation-avec-suivi-prolonge-points"
            className="px-0 pt-6 pb-10 text-xl text-center text-gray-900 border-2 bg-blue-0 border-lilas5 rounded-b-2xl md:text-base"
          >
            <li>
              {/* <img src={babyPhone} alt="icone de mégaphone" className="inline h-6 mr-1 align-baseline" /> */}
              - Étude de votre situation
            </li>
            <li>
              {/* <img src={babyPhone} alt="icone de mégaphone" className="inline h-6 mr-1 align-baseline" /> */}
              - 1h30 de call/skype
            </li>
            <li>
              {/* <img
                src={personalizedPlan}
                alt="icone de plan personnalisé"
                className="inline h-6 mr-1 align-baseline"
              /> */}
              - Plan individuel et personnalisé
            </li>
            <li>
              {/* <img src={bear} alt="icone de d'ours" className="inline h-6 mr-1 align-baseline" /> */}-
              Envoyé sous 2 jours ouvrés
            </li>
            <li>
              {/* <img src={calendar} alt="icone de de calendrier" className="inline h-6 mr-1 align-baseline" /> */}
              <span className="line-separator line-separator-purple tooltip">
                - 1 semaine de préparation (mise en place de la 1ère partie du plan)
                <span className="tooltiptext">
                  Accompagnement sous forme de réponses écrites (mail/messages), 2/jour
                </span>
              </span>
            </li>
            <li>
              {/* <img src={chat} alt="icone de de calendrier" className="inline h-6 mr-1 align-baseline" /> */}
              <span>
                <span className="line-separator tooltip">
                  - 2 semaines de suivi avec l&apos;agenda quotidien du sommeil
                  <span className="tooltiptext tooltiptext-blue">
                    L’agenda que vous allez remplir chaque jour fera l’objet d’une analyse, avec les
                    recommandations nécessaires, si besoin
                  </span>
                </span>
                {/* <span className="tooltiptext">
                  Répondre aux questions: l’objectif est de garder la bonne direction, afin d’arriver au
                  résultat attendu, par le chemin le plus court et le plus efficace.
                </span> */}
              </span>
            </li>
            <li>
              {/* <img src={footsteps} alt="icone de de calendrier" className="inline h-6 mr-1 align-baseline" /> */}
              <span className="line-separator line-separator-purple tooltip">
                - 1 entretien de 30~60min/semaine
                <span className="tooltiptext">
                  Si besoin pour vous apporter plus amples explications aux démarches à suivre
                </span>
              </span>
              {/* <span className="tooltiptext">
                L&apos;objectif est de suivre de près l’avancement dans la réalisation du plan du sommeil,
                pour une meilleure réussite.
              </span> */}
            </li>
            <li>
              {/* <img
                src={longerIfNeeded}
                alt="icone de de calendrier"
                className="inline h-6 mr-1 align-baseline"
              /> */}
              <span className="line-separator tooltip">
                - bilan final de 30~60min
                <span className="tooltiptext tooltiptext-blue">
                  Un entretien pour faire le bilan de la réussite et donner les suggestions pour l’avenir
                </span>
              </span>
            </li>
            <button
              className="absolute bottom-0 left-0 right-0 w-7/12 p-2 mx-auto -mb-4 font-bold text-white bg-blue-500 rounded-full cursor-pointer bg-ugly-lilas hover:bg-ugly-lilas-hovered font-muli"
              onClick={() => {
                createPaymentIntent(process.env.GATSBY_PRESTATION_SUIVI_ID)
                setTitle('Prestation avec suivi')
                setText(`Vous souhaitez atteindre le résultat en douceur, avec le support quotidien et durable de votre
              consultante.`)
                setPrice('369,00 €')
              }}
            >
              Choisir ce forfait
            </button>
          </ul>
        </section>

        <div className="flex justify-center w-4/5 mx-auto mt-6 overflow-hidden text-transparent line-separator line-separator-purple">
          _______________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________
        </div>

        <div className="flex flex-col mx-auto">
          <section
            id="nouveaux-nes"
            className="relative flex w-10/12 max-w-3xl mx-auto mt-6 mt-16 border-2 rounded-3xl border-lilas"
          >
            <div className="relative hidden w-48 pb-1/3 md:block md:h-auto h-80 lg:w-64">
              <Img
                fluid={image.five.childImageSharp.fluid}
                alt="nouveau né"
                className="absolute w-full h-full object-cover rounded-l-2.5xl bg-new-born-card relative"
              />
            </div>
            <div className="relative flex flex-col">
              <img
                src={blob}
                className="absolute top-0 right-0 z-10 h-32 -mt-16 -mr-16 transform -rotate-270"
              />
              <span className="absolute top-0 right-0 z-10 -mt-4 -mr-8 text-sm text-gray-800">
                0 à 4 mois
              </span>
              <div
                id="upper-part"
                className="relative flex flex-col pt-2 pb-6 text-lg bg-lilas rounded-tl-2xl md:rounded-none font-muli"
              >
                <h3 className="font-bold text-center text-gray-800">
                  <img
                    src={babyNewBornCta}
                    alt="icone de mobile"
                    className="inline h-6 mr-2 -ml-3 align-baseline"
                  />
                  Nouveaux-nés
                </h3>
                <p className="w-11/12 pl-4 text-xl text-gray-800 md:text-base">
                  Jeunes parents, pour connaître les bonnes pratiques d’un bon sommeil du bébé, c’est pour
                  vous !
                  <span className="absolute bottom-0 left-0 right-0 w-24 p-1 mx-auto -mb-4 text-base text-center text-blue-800 rounded-full bg-tealy-400 font-header">
                    59 euros
                  </span>
                </p>
              </div>
              <div
                id="lower-part"
                className="flex flex-col pb-8 border-0 rounded-bl-lg sm:rounded-br-3xl md:pb-6 md:pb-8 rounded-br-2xl sm:rounded-bl-none border-salmony-200"
              >
                <section className="pl-4 mt-4 text-gray-900 font-muli md:text-base text-md">
                  <span className="font-bold">En plus du questionnaire:</span>
                  <ul>
                    <li>
                      {/* <img src={babyPhone} alt="icone de mégaphone" className="inline h-6 mr-1 align-baseline" /> */}
                      - Entretien-consultation de 45min durant lequel nous allons aborder votre situation et
                      les outils à mettre en place
                    </li>
                    <li>
                      {/* <img src={mail} alt="icone de mail" className="inline h-6 mr-1 align-baseline" /> */}
                      - Suite à notre entretien, un guide, avec la recommandation de bonnes pratiques par mail
                    </li>
                  </ul>
                  <button
                    className="absolute bottom-0 left-0 right-0 w-3/4 w-7/12 px-4 py-3 mx-auto -mt-6 -mb-4 font-bold text-center text-white transition-all bg-white rounded-full shadow-sm cursor-pointer sm:-mb-6 md:-mb-6 md:ml-12 lg:ml-auto bg-additionnal-pricing hover:bg-additionnal-pricing-hovered transition-500"
                    onClick={() => {
                      createPaymentIntent(process.env.GATSBY_NOUVEAUX_NES_ID)
                      setTitle('Nouveaux-nés')
                      setText(`Jeunes parents, pour connaître les bonnes pratiques d’un bon sommeil du bébé, c’est pour
                  vous !`)
                      setPrice('59,00 €')
                    }}
                  >
                    Choisir ce forfait
                  </button>
                </section>
              </div>
            </div>
          </section>

          <section
            id="nouveaux-nes2"
            className="relative flex w-10/12 max-w-3xl mx-auto mt-16 border-2 rounded-3xl border-lilas3"
          >
            <div className="relative hidden w-48 pb-1/3 md:block md:h-auto h-80 lg:w-64">
              <Img
                fluid={image.seven.childImageSharp.fluid}
                alt="nouveau né"
                className="absolute w-full h-full object-cover rounded-l-2.5xl bg-additionnal-pricing"
              />
            </div>
            <div className="relative flex flex-col">
              <div
                id="upper-part"
                className="relative flex flex-col pt-2 pb-6 text-lg bg-lilas3 rounded-t-2xl md:rounded-tl-none font-muli"
              >
                <h3 className="text-xl font-bold text-center text-gray-800 md:text-base">
                  <img
                    src={additionnalPrestation}
                    alt="icone de mégaphone"
                    className="inline h-6 mr-2 -ml-3 align-baseline"
                  />
                  Une semaine supplémentaire de suivi
                </h3>
                <p className="w-11/12 pl-4 text-xl text-gray-800 md:text-base">
                  Disponible en plus de chaque forfait, si jamais vous avez besoin d&apos;assistance
                  ponctuelle après une formule.
                  <span className="absolute bottom-0 left-0 right-0 w-24 p-1 mx-auto -mb-4 text-base text-center text-blue-800 rounded-full bg-tealy-400 font-header">
                    60 euros
                  </span>
                </p>
              </div>
              <div
                id="lower-part"
                className="flex flex-col pb-8 border-0 rounded-bl-lg sm:rounded-br-3xl md:pb-6 md:pb-8 rounded-br-2xl sm:rounded-bl-none border-salmony-200"
              >
                <section className="pl-4 mt-4 text-gray-900 font-muli md:text-base text-md">
                  <ul className="text-xl md:text-base">
                    <li>- Uniquement pour ceux qui ont déjà pris un forfait</li>
                    <li>
                      {/* <img src={recidiveHelp} alt="icone de mail" className="inline h-6 mr-1 align-baseline" /> */}
                      - A utiliser s’il faut continuer le travail après le forfait principal, dans le cas
                      d&apos;une récidive, ou si vous avez à nouveau besoin d’une aide ponctuelle
                    </li>
                  </ul>
                  <button
                    className="absolute bottom-0 left-0 right-0 w-1/2 w-3/4 px-4 py-3 mx-auto -mt-6 -mb-12 font-bold text-center text-white transition-all bg-white rounded-full shadow-sm cursor-pointer sm:-mb-6 md:-mb-6 md:ml-12 lg:ml-auto bg-additionnal-pricing hover:bg-additionnal-pricing-hovered transition-500"
                    onClick={() => {
                      createPaymentIntent(process.env.GATSBY_SEMAINE_PLUS_ID)
                      setTitle('Une semaine supplémentaire de suivi')
                      setText(`Disponible en plus de chaque forfait, si jamais vous avez besoin d'assistance
                  ponctuelle après une formule.`)
                      setPrice('60,00 €')
                    }}
                  >
                    Prendre ce forfait complémentaire
                  </button>
                </section>
              </div>
            </div>
          </section>
        </div>
      </div>
    </Layout>
  )
}

export default pricing
